import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { maskEmail, userRoleRoute } from '../../Utils/service';
import BackToLoginButton from './backToLoginButton';
import Header from './header';
import {
  clearState,
  getCurrentUser,
} from '../../Redux/Slice/authenticationSlice';
import { ROUTE } from '../../Enum';
import { StoreState } from '../../Redux/Interface';

const AccountVerificationPage = () => {
  const getResponse = useLocation();
  const [email, setEmail] = useState<string>('');
  const dispatch = useDispatch();
  const { userData } = useSelector((state: StoreState) => state.user);
  const navigator = useNavigate();
  useEffect(() => {
    if (getResponse.state) {
      setEmail(maskEmail(getResponse?.state?.email));
    }
  }, [getResponse]);

  useEffect(() => {
    if (userData?.response?.isVerified) {
      navigator(userRoleRoute(userData?.response?.role));
    }
  }, [userData]);

  return (
    <>
      <Header />
      <BackToLoginButton isAccountVerification />
      <Box className="authentication-form-wrap">
        <Box className="text-center">
          <Typography
            className="fw-medium text-center"
            fontSize={{ xs: '20px', sm: '30px', lg: '40px' }}
          >
            A verification code has been sent to
            {` ${email}`}
          </Typography>

          <Typography className="text-center" margin="23px 0 12px">
            Check your email for a recovery link, then you can log into your
            account.
          </Typography>
          <Link
            to={`/${ROUTE.LOGIN}`}
            className="fw-semibold text-center color-blue"
            onClick={() => {
              dispatch(clearState());
              // @ts-expect-error Will fix this
              dispatch(getCurrentUser());
            }}
          >
            Log into Home Service Checklist
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default AccountVerificationPage;
