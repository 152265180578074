export interface LandingPageRegistrationForm {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  businessName: string;
  crm: string;
  businessAddress: string;
  primaryInterest: string;
}

export const MaxCompanyUsersMapping = {
  1: 10,
  11: 50,
  51: 100,
};

export const CompanyUserCountMapping = {
  1: '1 - 10',
  11: '11 - 50',
  51: '51 - 100',
  100: '100+',
};
