import CryptoJS from 'crypto-js';
import { JSEncrypt } from 'jsencrypt';
import { toast } from 'react-toastify';
import {
  AdminBackOfficeRoute,
  LocalStorage,
  ROUTE,
  ServiceAdvisorRoute,
  SuperAdminBackOfficeRoute,
  TechnicianRoute,
  UserAdminRole,
  UserRole,
} from '../Enum';
import { ExtendedWindow } from '../Interface/interface';
import ApiRequest from './apiRequest';

const SECRET_KEY = 'FEwlXqK3t3NGhp7K481sOVq5HkN6PfXchNNbWoLGy6jvJRtepF';

export function printLogs(name: string, data: object | string | boolean | any) {
  // eslint-disable-next-line no-console
  if (window.location.href.includes('localhost')) console.log(name, data);
}

export const removeAccessAndRefreshToken = () => {
  localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  localStorage.removeItem(LocalStorage.REFRESH_TOKEN);
};

export const logoutWithDelayAndRedirect = () => {
  setTimeout(() => {
    removeAccessAndRefreshToken();
    window.open(`${process.env.REACT_APP_URL}/${ROUTE.LOGIN}`, '_self');
  }, 1000);
};

export const handleApiError = (error: any) => {
  const errorMessage = error?.response?.data?.message;

  // Generate a consistent toastId based on the error message
  const getToastId = (msg: string) => `error-${msg}`;

  if (Array.isArray(errorMessage)) {
    const message = errorMessage.join(',\n');
    toast.error(message, {
      toastId: getToastId(message),
      style: { whiteSpace: 'pre-line' },
    });
  } else if (errorMessage) {
    toast.error(errorMessage, {
      toastId: getToastId(errorMessage),
    });
  }

  if (!error?.response) {
    const message = error?.message || 'Network Error';
    toast.error(message, {
      toastId: getToastId(message),
    });
  }
};

export const handleAsyncThunk = async (
  apiEndpoint: string,
  method: 'get' | 'post' | 'patch' | 'delete' | 'put',
  data: any = '',
  companyId?: string,
  useAccessToken?: boolean,
) => {
  try {
    const response = await ApiRequest(
      apiEndpoint,
      method,
      data,
      useAccessToken,
      companyId,
    );
    return { status: 200, response };
  } catch (error: any) {
    if (error.response?.status === 401) {
      toast.error('Session expired!, Please log in again.');
      logoutWithDelayAndRedirect();
    } else {
      handleApiError(error);
    }
    return error;
  }
};

export const removeContactFormate = (contactNumber: string) =>
  contactNumber.replace(/[(),\- ]/g, '');

export const encryptData = (data: string | undefined | null) => {
  try {
    if (data === null || data === undefined) {
      return '';
    }
    const encryptedData = CryptoJS.AES.encrypt(
      String(data),
      SECRET_KEY,
    ).toString();
    return encryptedData;
  } catch (error) {
    return '';
  }
};

export const decryptData = (data: string | null) => {
  try {
    if (data === null) {
      return null;
    }
    const decryptedData = CryptoJS.AES.decrypt(data, SECRET_KEY).toString(
      CryptoJS.enc.Utf8,
    );
    return decryptedData;
  } catch (error) {
    return null;
  }
};

export function statusManage(status: number) {
  if (status === 401) {
    window.location.href = `/${ROUTE.LOGIN}`;
    localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
  }
}

export async function generateRecaptchaToken(actionType?: string) {
  const siteKey = process.env.REACT_APP_CPATCHA_PUBLIC_KEY;
  const options = actionType ? { action: actionType } : undefined;
  const reCaptchaToken = await (
    window as ExtendedWindow
  )?.grecaptcha?.enterprise?.execute(String(siteKey), options);
  return reCaptchaToken || '';
}

export const checkEmailValidity = (emailId: string) => {
  if (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      emailId,
    )
  ) {
    return undefined;
  }
  return { isError: true, message: 'Email address is required.' };
};

export const checkEmptyFieldValidity = (value: string) => {
  if (value && value.trim().length > 0) {
    return undefined;
  }
  return true;
};

export const checkFirstNameValidity = (value: string) => {
  if (checkEmptyFieldValidity(value)) {
    return { isError: true, message: 'First name is required.' };
  }
  if (/\s/.test(value.trim())) {
    return { isError: true, message: 'Space not allowed in first name.' };
  }
  return undefined;
};
export const checkLastNameValidity = (value: string) => {
  if (checkEmptyFieldValidity(value)) {
    return { isError: true, message: 'Last name is required.' };
  }
  if (/\s/.test(value.trim())) {
    return { isError: true, message: 'Space not allowed in last name.' };
  }
  return undefined;
};

export const checkCompanyNameValidity = (value: string) => {
  if (value && /^[A-Za-z0-9\s&,'.-]+$/gm.test(value)) {
    return undefined;
  }
  return true;
};

export const checkPhoneValidity = (phone: string) => {
  if (!phone) {
    return { isError: true, message: 'Phone number is required.' };
  }
  if (/^\(\d{3}\) \d{3}-\d{4}$/gm.test(phone)) {
    return { isError: false, message: null };
  }
  return { isError: true, message: 'Please enter the valid phone number.' };
};

export const checkDateValidity = (date: string) => {
  if (!date) {
    return true;
  }
  return undefined;
};

export const handleUserCountValidity = (userCount: number) => {
  if (!userCount) {
    return { isError: true, message: 'User count is required.' };
  }
  if (userCount > 100000 || userCount < 1) {
    return { isError: true, message: 'User count must between 1 and 100000' };
  }
  return undefined;
};

export const checkPasswordValidity = (password: string) => {
  // Use the regex to check password validity
  // Check each criterion and return corresponding error messages
  if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d]).{6,12}$/.test(password)) {
    return {
      isError: true,
      message:
        'Password Must be 6-12 character with lowercase, uppercase, and 1 special character.',
    };
  }

  // Password meets all criteria
  return undefined;
};

export const checkIfValueIsEmpty = (value: string) => {
  if (!value || value.trim().length === 0) {
    return true;
  }
  return null;
};

export function maskEmail(emailId: string) {
  if (emailId) {
    const [username, domain] = emailId.split('@');
    const obscuredUsername =
      username.slice(0, 3) + '*'.repeat(username.length - 3);
    const [domainName, domainExtension] = domain.split('.');
    const obscuredDomain = `${
      domainName.slice(0, 2) + '*'.repeat(domainName.length - 2)
    }.${domainExtension.slice(0, 2)}${'*'.repeat(domainExtension.length - 2)}`;

    return `${obscuredUsername}@${obscuredDomain}`;
  }
  return '';
}

export function decodeToken(token: string) {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url?.replace(/-/g, '+')?.replace(/_/g, '/');
    const payload = JSON.parse(atob(base64));

    return payload;
  } catch (e) {
    return null;
  }
}

export function getToken(isUseAccessToken: boolean) {
  // Return early if access token usage is disabled
  if (!isUseAccessToken) {
    return undefined;
  }

  try {
    // Retrieve and decrypt tokens from local storage
    const accessToken = String(
      decryptData(localStorage.getItem(LocalStorage.ACCESS_TOKEN)),
    );
    const refreshToken = String(
      decryptData(localStorage.getItem(LocalStorage.REFRESH_TOKEN)),
    );

    // Validate that both tokens exist
    if (!accessToken || !refreshToken) {
      return undefined;
    }

    // Return formatted Bearer token
    return `Bearer ${accessToken}`;
  } catch (e) {
    // Return undefined if any error occurs during token retrieval or processing
    return undefined;
  }
}

export const loadRecaptcha = () => {
  const script = document.createElement('script');

  script.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_CPATCHA_PUBLIC_KEY}`;
  script.id = 'recaptcha-script';
  script.async = true;

  document.body.append(script);
};

export const removeRecaptcha = () => {
  const script = document.getElementById('recaptcha-script');
  if (script) {
    script.remove();
  }

  const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
  if (recaptchaElems.length) {
    recaptchaElems[0].remove();
  }
};

export const passwordEncrypt = (publicKey: string, password: string) => {
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(publicKey);
  const encryptedText = jsEncrypt.encrypt(password);
  return encryptedText || '';
};

export const validateFirstAndLastName = (
  value: string,
  isFirstName: boolean,
) => {
  if (checkIfValueIsEmpty(value)) {
    return `${isFirstName ? 'First' : 'Last'} name is required.`;
  }
  if (/\s/.test(value.trim())) {
    return `Space not allowed in ${isFirstName ? 'first' : 'last'} name.`;
  }
  return null;
};

export const handleMenuLinkClick = (targetId: string) => {
  const targetElement = document.getElementById(targetId);
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: 'smooth' });
  }
};

export const isUserAdminRole = (role: string) => UserAdminRole.includes(role);

export const userRoleRoute = (role: string) => {
  const baseUrl = process.env.REACT_APP_URL;
  if (role === UserRole.SERVICE_ADVISOR) {
    return `${baseUrl}/${ServiceAdvisorRoute.PENDING_JOBS}`;
  }
  if (isUserAdminRole(role)) {
    return `${baseUrl}/${AdminBackOfficeRoute.TEMPLATE}`;
  }
  if (role === UserRole.TECHNICIAN) {
    return `${baseUrl}/${TechnicianRoute.CHECKLIST}`;
  }
  return `${baseUrl}/${SuperAdminBackOfficeRoute.TEMPLATE}`;
};
